import React from "react";
import { useState } from "react";
import { BiGlobe, BiHomeAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { PaymentReserveCreate } from "../../action/reserveAction";
import PayementList from "./page-componets/PayementList";
import AllNavbar from "./AllNavBar";
// import { addStudentAction } from "../../../action/StudentAction";

const PaymentPage = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");

  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities] = useState([
    "Addis Ababa",
    "Dire Dawa",
    "Mekelle",
    "Gondar",
    "Bahir Dar",
    "Jimma",
    "Hawassa",
    "Adama",
    // Add more cities as needed
  ]);
  const [subcity, setSubCity] = useState("");
  const [worda, setWorda] = useState("");

  const [houseNumber, setHouseNumber] = useState("");
  const [kId, setKId] = useState("");
  const [doc, setDoc] = useState("");

  const [married, setMarried] = useState("");
  const [bankuserName, setBankuserName] = useState("");

  const [bankRecipt, setBankRecipt] = useState("");
  const [serviceBankRecipt, setServiceBankRecipt] = useState(""); // Renamed to follow convention

  const signUpSubmitHandler = (e) => {
    e.preventDefault();

    // Collect all form data into a single object
    const formData = {
      firstName,
      lastName,
      email,
      phonenumber,
      age,
      gender,
      selectedCity,
      subcity,
      worda,
      houseNumber,
      kId,
      doc,
      married,
      bankuserName,
      bankRecipt,
      serviceBankRecipt,
    };

    // Add your logic to handle the form data, for example, send it to an API or perform further processing
    console.log(formData);

    dispatch(PaymentReserveCreate(formData));
    // Now you can use the formData object as needed in your application
  };
  const [openIndices, setOpenIndices] = useState([0]); // Set the initial state to include the index of the first item

  const toggleAccordion = (index) => {
    setOpenIndices((prevOpenIndices) =>
      prevOpenIndices.includes(index)
        ? prevOpenIndices.filter((i) => i !== index)
        : [...prevOpenIndices, index]
    );
  };

  const accordionData = [
    {
      title: "Option One",
      content:
        "100% payment will have 1,000,000 birr(One million Birr) discount. ",
    },
    {
      title: "Option Two",
      content:
        "Down payment 60% \nFinal payment (payable after     one year): 40% It will have 500,000 (Five Hundred Thousand ) Birr discount ",
    },
    {
      title: "Option Three",
      content:
        "Flexible payment option\nDown payment 10%\nFirst payment 25% During the construction of Basements and Ground floor (For Substructure work)\nSecond payment 40% During  the entire floor of the  building is under construction (For Superstructure work)\nFinal payment 25% For Semi-finishing work \nN.B:- The installments will be paid out over a 48-month payment schedule.",
    },
    {
      title: "Option Four",
      content:
        "Shop payment option \nDown payment 20%\nThe remaining 80% will be paid out over a 36-month payment schedule. ",
    },
  ];
  return (
    <>
      <AllNavbar />
      <div className="pt-10 pb-16 mt-40 lg:mt-12 md:16">
        <div className="flex-1 basis-[20rem]">
          {/* <div className="flex  mt-10 ml-36 ">
            <h1 className="text-4xl text-orange-500 font-semibold">
              Payment Options
            </h1>
          </div> */}

          {/* Right column container with background and description*/}
          <div id="accordion-open" data-accordion="open">
            <h2 className=" section-title mx-auto text-4xl font-extrabold  text-primary">
              Payment Options
            </h2>
            {accordionData.map((item, index) => (
              <div key={index}>
                <h2 id={`accordion-open-heading-${index + 1}`}>
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
                    data-accordion-target={`#accordion-open-body-${index + 1}`}
                    aria-expanded={openIndices.includes(index)}
                    aria-controls={`accordion-open-body-${index + 1}`}
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="flex items-center justify-center w-full text-xl font-bold text-primary">
                      {item.title}
                    </span>

                    <svg
                      data-accordion-icon
                      className={`w-3 h-3 transition-transform duration-300 ${
                        openIndices.includes(index)
                          ? "transform rotate-180"
                          : ""
                      } shrink-0`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  </button>
                </h2>
                <div
                  id={`accordion-open-body-${index + 1}`}
                  className={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 ${
                    openIndices.includes(index) ? "" : "hidden"
                  }`}
                  aria-labelledby={`accordion-open-heading-${index + 1}`}
                >
                  <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                    <ul className="list-disc pl-6">
                      {item.content.split("\n").map((point, i) => (
                        <li
                          key={i}
                          className="mb-2 text-gray-500 text-xl dark:text-gray-400"
                        >
                          {point.trim()}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-wrap gap-10">
          <div className="flex-1 basis-[28rem]">
            {/* Left column container*/}
            <div className="px-4 md:px-0 lg:w-10/12 mt-4">
              <div className="md:mx-6 md:p-12">
                {/*Logo*/}
                <div className="text-center">
                  <img
                    className="mx-auto w-48"
                    src="../../../images/mecht.png"
                    alt="logo"
                  />
                  <h4 className="mb-2 mt-1 pb-1 text-4xl font-semibold">
                    Mechot Real Estate
                  </h4>
                  <h4 className="mb-12 pb-1 text-3xl font-semibold">
                    You Deserve Comfort!
                  </h4>
                </div>
                <form
                  onSubmit={signUpSubmitHandler}
                  className="bg-gradient-to-r from-g to-white p-8 rounded-md shadow-md"
                >
                  <h2 className="text-lg font-semibold mb-6 dark:text-white">
                    Register to Your Platform
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* First Name */}
                    <div className="mb-4">
                      <label
                        htmlFor="fname"
                        className="block text-gray-700 dark:text-gray-300"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        id="fname"
                        className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                        placeholder="First Name"
                        name="firstname"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>

                    {/* Last Name */}
                    <div className="mb-4">
                      <label
                        htmlFor="lname"
                        className="block text-gray-700 dark:text-gray-300"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="lname"
                        className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                        placeholder="Last Name"
                        name="lastname"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* Email Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  {/* Phone Number Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="phonenumber"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="phonenumber"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="0910289734"
                      name="phonenumber"
                      value={phonenumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  {/* Age Input */}
                  {/* <div className="mb-4">
                    <label
                      htmlFor="age"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Age
                    </label>
                    <input
                      type="text"
                      id="age"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Age"
                      name="age"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                    />
                  </div> */}
                  {/* Gender Radio Buttons */}
                  <div className="mb-4">
                    <label className="block text-gray-700 dark:text-gray-300">
                      Choose your gender:
                    </label>
                    <div className="flex gap-4">
                      {["male", "female"].map((option) => (
                        <label key={option} className="flex items-center">
                          <input
                            type="radio"
                            value={option}
                            checked={gender === option}
                            className="form-radio"
                            onChange={() => setGender(option)}
                          />
                          <span className="ml-2">
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Select City Dropdown */}
                  <div className="mb-4">
                    <label
                      htmlFor="city"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Select a city in Ethiopia:
                    </label>
                    <select
                      id="city"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                      className="w-full bg-white border border-gray-300 p-2 rounded"
                    >
                      <option value="" disabled>
                        Choose a city
                      </option>
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Subcity Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="subcity"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Sub-city
                    </label>
                    <input
                      type="text"
                      id="subcity"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Sub-city"
                      name="subcity"
                      value={subcity}
                      onChange={(e) => setSubCity(e.target.value)}
                    />
                  </div>
                  {/* Worda Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="worda"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Worda
                    </label>
                    <input
                      type="text"
                      id="worda"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Worda"
                      name="worda"
                      value={worda}
                      onChange={(e) => setWorda(e.target.value)}
                    />
                  </div>
                  {/* House Number Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="housenumber"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      House Number
                    </label>
                    <input
                      type="text"
                      id="housenumber"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="House Number"
                      name="housenumber"
                      value={houseNumber}
                      onChange={(e) => setHouseNumber(e.target.value)}
                    />
                  </div>
                  {/* Kebele ID Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="kebelid"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Kebele ID
                    </label>
                    <input
                      type="file"
                      id="kebelid"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Kebele ID"
                      name="kebelid"
                      value={kId}
                      onChange={(e) => setKId(e.target.value)}
                    />
                  </div>
                  {/* Bank Name Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="bankname"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Bank Name
                    </label>
                    <input
                      type="text"
                      id="bankname"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Bank Name"
                      name="bankname"
                      value={bankuserName}
                      onChange={(e) => setBankuserName(e.target.value)}
                    />
                  </div>
                  {/* Attach Doc Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="doc"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Attach Doc
                    </label>
                    <input
                      type="file"
                      id="doc"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Doc"
                      name="doc"
                      value={doc}
                      onChange={(e) => setDoc(e.target.value)}
                    />
                  </div>
                  {/* Bank Receipts Input */}
                  <div className="mb-4">
                    <label
                      htmlFor="bankrecipts"
                      className="block text-gray-700 dark:text-gray-300"
                    >
                      Bank Receipts
                    </label>
                    <input
                      type="file"
                      id="bankrecipts"
                      className="input-field peer w-full border rounded bg-gray-100 px-3 py-2 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Bank Receipts"
                      name="bankrecipts"
                      value={bankRecipt}
                      onChange={(e) => setBankRecipt(e.target.value)}
                    />
                  </div>
                  {/* Submit Button */}
                  <div className="text-center">
                    <input
                      type="submit"
                      className="inline-block rounded-lg px-6 py-2 text-sm font-semibold text-white bg-blue-500 shadow-md hover:shadow-lg focus:outline-none focus:ring focus:border-blue-300"
                      value="Submit Form"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPage;
