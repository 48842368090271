import React from 'react';
import PortifolioList from "../components/portifolio/PortifolioList";
import PortifolioGallery from '../components/portifolio/PortifolioGallery';
import AllNavbar from '../components/common/AllNavBar';

const GalleryPage = () => {
  return (
    <>
      <AllNavbar />
      <div className="pt-20 px-[3%] md:px-[6%] mt-28 lg:mt-12 md:16">
        <PortifolioGallery />
      </div>
    </>
  );
}

export default GalleryPage;
