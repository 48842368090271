import axios from "axios";
import {
  PROPERTIES_LIST_FAIL,
  PROPERTIES_LIST_REQUEST,
  PROPERTIES_LIST_SUCCESS,
} from "../constants/PropertiesCOnstants";

const BASE_URL = "127.0.0.1:8000/properties/properties_list";

//127.0.0.1:8000/api/schema/docs/#/properties/properties_list

export const propertiesList = () => async (dispatch) => {
  dispatch({
    type: PROPERTIES_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(
      "https://api.mechotrealestate.com/properties/"
    );
    console.log("action properties", data);
    dispatch({
      type: PROPERTIES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROPERTIES_LIST_FAIL,
      payload: error,
    });
  }
};
