import { useState } from "react";
import { FiArrowRight, FiHome, FiPhone } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { ReserveCreate } from "../../../action/reserveAction";

const Invest = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    const reserve = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
    };
    console.log("reserve", reserve);
    dispatch(ReserveCreate(reserve));
  };

  return (
    <div className="py-10 px-5 sm:px-10">
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3">
        <div className="md:col-span-1">
          <img
            src="../../../../Video/photo1707971888.jpeg"
            alt="Property"
            className="w-full h-full rounded-lg"
          />
        </div>
        <div className="md:col-span-2 bg-white rounded-lg shadow-md flex flex-col justify-center">
          <div className="p-8 md:ml-10">
            <h1 className="text-2xl font-semibold mb-5">አሁኑኑ ቀጠሮ ይያዙ</h1>
            <form onSubmit={submitHandler} className="space-y-4">
              <div className="mb-3">
                <label htmlFor="firstName" className="block mb-1">
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  className="w-full bg-white rounded-lg px-3 py-2 outline-none text-gray-800"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="block mb-1">
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  className="w-full bg-white rounded-lg px-3 py-2 outline-none text-gray-800"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="block mb-1">
                  Phone Number
                </label>
                <input
                  id="phoneNumber"
                  type="tel"
                  className="w-full bg-white rounded-lg px-3 py-2 outline-none text-gray-800"
                  placeholder="Enter your phone number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <button className="w-2/5 px-4 py-2 rounded-md bg-orange-600 text-white">
                Contact
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invest;

// <div className="pt-10 pb-16">
//   <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
//     <div className="relative flex items-center justify-center">
//       <img
//         src="../../../../Video/inverst-main.jpeg"
//         alt="Property"
//         className="w-full h-auto md:h-full rounded-lg shadow-md"
//       />
//     </div>
//     <div className="relative flex-col gap-5 p-5 bg-white text-gray-600 flex items-center justify-center">
//       <div className="p-8 rounded-md shadow-md text-gray-800 flex flex-col items-center md:items-start">
//         <h1 className="text-2xl font-semibold mb-5">አሁኑኑ ቀጠሮ ይያዙ</h1>
//         <form onSubmit={submitHandler} className="space-y-4">
//           <div className="mb-3">
//             <label htmlFor="firstName" className="block mb-1">
//               First Name
//             </label>
//             <input
//               type="text"
//               id="firstName"
//               className="w-full bg-white rounded-lg px-3 py-2 outline-none text-gray-800"
//               placeholder="First Name"
//               value={firstName}
//               onChange={(e) => setFirstName(e.target.value)}
//             />
//           </div>
//           <div className="mb-3">
//             <label htmlFor="lastName" className="block mb-1">
//               Last Name
//             </label>
//             <input
//               type="text"
//               id="lastName"
//               className="w-full bg-white rounded-lg px-3 py-2 outline-none text-gray-800"
//               placeholder="Last Name"
//               value={lastName}
//               onChange={(e) => setLastName(e.target.value)}
//             />
//           </div>
//           <div className="mb-3">
//             <label htmlFor="phoneNumber" className="block mb-1">
//               Phone Number
//             </label>
//             <input
//               type="tel"
//               id="phoneNumber"
//               className="w-full bg-white rounded-lg px-3 py-2 outline-none text-gray-800"
//               placeholder="Enter your phone number"
//               value={phoneNumber}
//               onChange={(e) => setPhoneNumber(e.target.value)}
//             />
//           </div>
//           <button className="w-full px-4 py-2 rounded-md bg-orange-600 text-white">
//             Contact
//           </button>
//         </form>
//       </div>
//     </div>
//   </div>
// </div>;
