import React from "react";
import { BiCreditCard, BiGlobe, BiHomeAlt, BiRightArrow } from "react-icons/bi";

const Vision = () => {
  return (
    <div className="pt-16 pb-20">
      <div className="flex flex-wrap gap-24">
        <div className="relative flex-1 basis-[22rem]">
          {/* <h1 className="sub-heading">about us</h1> */}
          <h1 className="heading">Vision</h1>
          <p className="mt-3">
            To provide affordable housing solutions to Ethiopia's growing
            population, ensuring that quality housing is accessible to all,
            regardless of income level.
          </p>
          <h1 className="heading">Mission</h1>
          <p className="mt-3">
            Building cost-effective housing projects at international standards
            using innovative construction methods and technologies while
            maintaining a commitment to sustainability and community
            development.
          </p>
          <div className="mt-4">
            <h1 className="heading"> Core Values</h1>
            <div className="flex-align-center gap-x-2 mt-3">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">professionalism</h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>

            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">result oriented</h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>
            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">Integrity</h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>
            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">Customer centered</h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>
            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">Team work</h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex-1 basis-[18rem] border">
          <img
            src="/images/property (16).jpg"
            alt=""
            className="object-cover w-full h-full rounded-lg"
          />
          {/* <img
            src="/images/property (26).jpg"
            alt=""
            className="absolute object-cover w-48 h-64 border-4 border-white rounded-lg sm:w-72 sm:h-80 dark:border-dark -bottom-20 -right-2 md:-right-20"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Vision;
