import {
  BiArrowFromLeft,
  BiArrowFromRight,
  BiCreditCard,
  BiGlobe,
  BiHomeAlt,
  BiRightArrow,
} from "react-icons/bi";

const AboutUs = () => {
  return (
    <div className="pt-16 pb-20">
      <div className="flex flex-wrap gap-24">
        <div className="relative flex-1 basis-[18rem] border">
          <img
            src="../../../../Video/mechot_view.jpg"
            alt=""
            className="object-cover w-full h-full rounded-lg"
          />
          {/* <img
            src="/images/property (26).jpg"
            alt=""
            className="absolute object-cover w-48 h-64 border-4 border-white rounded-lg sm:w-72 sm:h-80 dark:border-dark -bottom-20 -right-2 md:-right-20"
          /> */}
        </div>
        <div className="relative flex-1 basis-[22rem]">
          {/* <h1
            className="mx-auto heading"
            style={{
              color: "orange",
              textAlign: "center",
              fontSize: "2em",
              margin: "20px 0",
            }}
          >
            About Us
          </h1> */}
          <h2 className=" section-title mx-auto text-4xl font-extrabold  text-primary">
            About Us
          </h2>
          {/* <h1 className="sub-heading text-3xl">about us</h1> */}
          <h1 className="heading"> Mechot Real Estate </h1>
          <p className="mt-3">
            Mechot Real Estate sc. is a forward-thinking company dedicated to
            address Ethiopia's housing challenges. Founded by experienced
            individuals, Mechot aims to revolutionize the real estate sector by
            offering affordable and efficient housing solutions. Our site is
            located in Messalemia (Kuasmeda).
          </p>
          {/* Our site is located in Messalemia commonly known us kuasmeda. It is
          also ideally situated around commercial areas such as piassa and
          Merkato. Which has top benefits to a better business and employment
          opportunities, excellent Return-On Investment, better access to
          amenities and proximity to shops. It is very close to numerous
          well-known stadium, hospitals and primary and secondary schools. */}
          <p className="mt-2">
            The building offers a mix of residential and commercial spaces
            strategically located near commercial hubs like Piassa and Merkato.
            Additionally, it is conveniently close to amenities, schools,
            hospitals, and recreational areas.Our mixed apartment model provides
            an ideal environment for living and working, promising a high
            Return-On Investment and business opportunities.
          </p>

          <div className="mt-4">
            <div className="flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  the perfect residency
                </h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>

            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  global architect experts
                </h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>

            <div className="mt-3 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <BiRightArrow />
              </div>
              <div>
                <h1 className="font-semibold capitalize">
                  total payment transparency
                </h1>
                {/* <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Saepe, quisquam?
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
