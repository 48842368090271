import { useDispatch, useSelector } from "react-redux";
import { dataStore } from "../../../features/dataSlice";
import SingleFeedCard from "./SingleFeedCard";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { useEffect } from "react";
import { createNews, newsList } from "../../../action/newsAction";
const BlogPostsList = () => {
  const { currentDataItems } = useSelector(dataStore);
    const dispatch = useDispatch();
    const { loading, error, news } = useSelector((state) => state.NewsList);

    console.log("data @ news page");
    // const handleSubmit = () => {
    //   createNews(news)
    // }
    console.log(news);
    useEffect(() => {
      dispatch(newsList());
    }, [dispatch]);
  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      {news?.map((item) => (
        <div
          key={item.id}
          className="bg-white dark:bg-dark-light border dark:border-card-dark rounded-lg overflow-hidden shadow-md"
        >
          <Link to={`/blog/${item.id}`} className="block group">
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-[300px] object-cover group-hover:scale-105 transition-transform"
            />
          </Link>
          <div className="p-4">
            <div class="p-6">
              <h5 class="mb-3 text-lg font-bold text-black">{item.title}</h5>
              <p class="mb-4 text-neutral-500 dark:text-neutral-300">
                {/* <small>
              Published <u>{items.created_date}</u> by{" "}
              <a href="#!">{items.created_by.username}</a>
            </small> */}
              </p>
              <p class="mb-4 pb-2 text-black">{item.description}</p>
              <Link
                to={`/projectdetails/${item.id}`}
                class="inline-block rounded-full bg-blue-500 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPostsList;

