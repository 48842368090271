import axios from "axios";
import {
  PAYMENTRESERVE_CREATE_FAIL,
  PAYMENTRESERVE_CREATE_REQUEST,
  PAYMENTRESERVE_CREATE_SUCCESS,
  RESERVE_CREATE_FAIL,
  RESERVE_CREATE_REQUEST,
  RESERVE_CREATE_SUCCESS,
} from "../constants/PayementReserveConstants";

export const PaymentReserveCreate = (reserve) => async (dispatch) => {
  dispatch({
    type: PAYMENTRESERVE_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      "https://api.mechotrealestate.com/registrations/",
      reserve,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("PAYMENTRESERVE_CREATE_REQUEST data @ action");
    console.log(data);

    dispatch({
      type: PAYMENTRESERVE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAYMENTRESERVE_CREATE_FAIL,
      payload: error,
    });
  }
};

export const ReserveCreate = (reserve) => async (dispatch) => {
  dispatch({
    type: RESERVE_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      "https://api.mechotrealestate.com/reserve/",
      reserve,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("reserve data @ action");
    console.log(data);

    dispatch({
      type: RESERVE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESERVE_CREATE_FAIL,
      payload: error,
    });
  }
};
