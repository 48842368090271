/* eslint-disable jsx-a11y/anchor-is-valid */

import { BiBuildingHouse } from "react-icons/bi";
import {
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
// import {
//   FiPhone,
//   FiFacebook,
//   FaTwitter,
//   FaInstagram,
//   FaLinkedin,
//   FaEnvelope,
//   FaMapMarkerAlt,
// } from "react-icons/all";

const Footer = () => {
  return (
    <div className="text-slate-200">
      <footer className="py-8">
        <div className="container mx-auto flex flex-col md:flex-row gap-8">
          <div className="flex-1 md:flex-none w-full md:w-auto mb-8 md:mb-0">
            <Link to="/" className="flex items-center gap-2">
              <img
                src="../../../images/logo.png"
                alt="Mechot Real Estate Logo"
                className="w-16 h-32 md:w-16 md:h-16 object-contain"
              />
              <h1 className="hidden md:block text-xl font-bold text-white">
                Mechot Real Estate
              </h1>
            </Link>

            <div className="mt-3">
              <p className="text-5xl font-semibold text-white mb-4">
                በምቾት ይኑሩ!
              </p>

              <div className="flex flex-col text-white">
                <div className="flex items-center mt-8 md:mt-0">
                  <FiPhone className="text-lg mr-2" />
                  <a>
                    {" "}
                    <p className="text-lg">Phone: {""}09 53 92 22 22 </p>
                  </a>
                </div>
                <div className="flex items-center mt-8 md:mt-0">
                  <FiPhone className="text-lg mr-2" />
                  <a>
                    <p className="text-lg">Phone: 09 11 64 20 52</p>
                  </a>
                </div>
                <div className="flex items-center mb-3">
                  <FaEnvelope className="text-lg mr-2" />
                  <a>
                    <p className="text-lg">Email: mechotrealestate@gmail.com</p>
                  </a>
                </div>
                <div className="flex items-center">
                  <FaMapMarkerAlt className="text-lg mr-2" />
                  <a>
                    <p className="text-lg">
                      Office Address: Addis Ababa, Piassa Country Tower 2nd
                      floorG2-19
                    </p>
                  </a>
                </div>
              </div>

              <div className="gap-5 my-6 flex-center-center">
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a>
                    {" "}
                    <FiFacebook />
                  </a>
                </div>
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="https://wa.me/message/DVFS6IEIM3USD1">
                    {" "}
                    <FaWhatsapp />
                  </a>
                </div>
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="https://www.instagram.com/mechot_realestate?igsh=MTQzbmF3aTVxcXIwZw==">
                    <FaInstagram />
                  </a>
                </div>
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <a href="https://t.me/mechot_realestate">
                    {" "}
                    <FaTelegram />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 md:w-1/3 lg:w-1/4 mb-8 md:mb-0">
            <h2 className="text-2xl font-bold text-white mb-4">Company</h2>
            <ul className="list-none p-0">
              <li className="my-3">
                <Link
                  to="/about-us"
                  className="text-lg text-muted hover:text-primary transition duration-300"
                >
                  About Us
                </Link>
              </li>
              <li className="my-3">
                <Link
                  to="/services"
                  className="text-lg text-muted hover:text-primary transition duration-300"
                >
                  Services
                </Link>
              </li>
              {/* <li className="my-3">
                <Link
                  to="/blog"
                  className="text-lg text-muted hover:text-primary transition duration-300"
                >
                  Blog
                </Link>
              </li> */}
              <li className="my-3">
                <Link
                  to="/properties"
                  className="text-lg text-muted hover:text-primary transition duration-300"
                >
                  Properties
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex-1 md:w-full lg:w-3/4 mt-8 md:mt-0 ">
            <h1 className="text-xl font-semibold text-white mb-4">About us</h1>
            <p className="text-lg ">
              Mechot Real Estate is a visionary real estate company established
              by individuals who share the same goal of solving the housing
              problem faced by many Ethiopians. With extensive business
              experience and a passion for creating affordable and
              time-efficient housing solutions, Mechot Real Estate aims to
              transform the real estate industry in Ethiopia.
            </p>
          </div>
        </div>
      </footer>
      <div className="py-2 mt-3 text-center border-t text-muted border-dark">
        <a href="https://simbatech.et/">
          Created By{" "}
          <span className="text-primary">Simbatech tecnology solution</span> |
          All Rights Reserved
        </a>
      </div>
    </div>
  );
};

export default Footer;
