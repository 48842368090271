import { services } from "../../../data/dummyData";

const Services = () => {
  return (
    <div className="pt-10 pb-16 bg-slate-50">
      <div className="text-center">
        {/* <h1 className="mx-auto sub-heading"> Our Services</h1> */}
        <h2 className=" section-title mx-auto text-4xl font-extrabold  text-primary">
          Our Services
        </h2>
      </div>
      {/* <div className="grid grid-cols-1 gap-4 mt-5 sm:grid-cols-2 md:grid-cols-3">
        {services.map(({ id, name, icon, text }) => (
          <div
            className="p-3 text-center rounded-lg hover:card-shadow hover:border-t-4 hover:border-t-primary dark:hover:bg-card-dark"
            key={id}
          >
            <div className="icon-box !opacity-100 !w-14 !h-14 mx-auto !bg-primary/20 text-primary hover:!bg-primary hover:text-white">
              <div className="text-2xl"> {icon}</div>
            </div>
            <h1 className="mt-2 text-2xl font-extrabold ">{name}</h1>
            <p className="mt-2 text-black">{text}</p>
          </div>
        ))}
      </div> */}
      <div className="grid grid-cols-1 gap-4 mt-5 sm:grid-cols-2 md:grid-cols-3">
        {services.map(({ id, name, icon, text, image }) => (
          <div
            key={id}
            className="relative rounded-lg overflow-hidden bg-white shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:bg-orange-300"
          >
            <div className="p-3 text-center">
              <div className="flex justify-center items-center mx-auto w-24 h-24  rounded-full">
                <img src={image} className="w-full h-full object-cover" />
              </div>
              <h1 className="mt-3 text-2xl font-extrabold">{name}</h1>
              <p className="mt-4 text-black text-xl text-md">{text}</p>
              <a href="#" className="text-blue-500 hover:underline block mt-2">
                Read more
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
