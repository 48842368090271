import AllNavbar from "../components/common/AllNavBar";
import {
  Categories,
  Counter,
  Feeds,
  Services as ServicesList,
  Team,
} from "../components/common/page-componets";

const Services = () => {
  return (
    <>
      <AllNavbar/>
      <div className="pt-20 px-[3%] md:px-[6%] mt-28 lg:mt-12 md:16">
              

        <ServicesList />
        <Categories />
        {/* <Counter /> */}
        {/* <Team /> */}
        {/* <Feeds /> */}
      </div>
    </>
  );
};

export default Services;
