import AllNavbar from "../components/common/AllNavBar";
import { ContactInfo, Form, Map } from "../components/contact";

const Contact = () => {
  return (
    <>
      <AllNavbar />
      <div className=" pt-20 px-[3%] md:px-[6%] mt-28 lg:mt-8 md:16">
        <ContactInfo />
        <Map />

        <Form />
      </div>
    </>
  );
};

export default Contact;
