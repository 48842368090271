import axios from "axios";
import {
  GALLERY_LIST_FAIL,
  GALLERY_LIST_REQUEST,
  GALLERY_LIST_SUCCESS,
} from "../constants/GalleryConstants";
import { PROJECT_DETAILS_FAIL, PROJECT_DETAILS_REQUEST, PROJECT_DETAILS_SUCCESS } from "../constants/projectConstants";

export const galleryList = () => async (dispatch) => {
  dispatch({
    type: GALLERY_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(
      "https://api.mechotrealestate.com/gallery/"
    );
    // const { data } = await axios.get("http://127.0.0.1:8000/gallery/");

    dispatch({
      type: GALLERY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GALLERY_LIST_FAIL,
      payload: error,
    });
  }
};

export const testimonialDetailById = (id) => async (dispatch) => {
  dispatch({ type: PROJECT_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(
      `https://api.mechotrealestate.com/project/${id}`
    );
    dispatch({
      type: PROJECT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_DETAILS_FAIL,
      payload: error,
    });
  }
};
