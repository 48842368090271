export const navLinks = [
  {
    id: 1,
    linkText: "Home",
    url: "/",

  },
  {
    id: 2,
    linkText: "About Us",
    url: "/about-us",
 
  },
  {
    id: 3,
    linkText: "Services",
    url: "/services",
  
  },
  {
    id: 4,
    linkText: "Payment",
    url: "/payment",

  },
  // {
  //   id: 5,
  //   linkText: "Blog",
  //   url: "/blog",

  // },
  {
    id: 6,
    linkText: "Contact",
    url: "/contact",
  },
  {
    id: 7,
    linkText: "Properties",
    url: "/properties",
 
  },
    {
    id: 8,
    linkText: "Gallery",
    url: "/gallery",
 
  },
];
