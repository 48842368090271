import Navbar from "../components/common/Navbar";
import {
  AboutUs,
  Brands,
  Feeds,
  Team,
  Testimonial,
} from "../components/common/page-componets";
import AllNavbar from "../components/common/AllNavBar";
import Vision from "../components/common/page-componets/Vision";

const About = () => {
  return (
    <>
      <AllNavbar />
      <div className="pt-20 px-[3%] md:px-[6%] mt-16 lg:mt-8 md:12">
        <AboutUs />
        <Vision />
        {/* <Team /> */}
        {/* <Testimonial /> */}
        {/* <Brands /> */}
        {/* <Feeds /> */}
      </div>
    </>
  );
};

export default About;
